<template>
  <AbstractDataTable
    ref="breakingNewsTable"
    :headers="tableHeaders"
    :items="list"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    :button-required-permissions="requiredPermissions"
    store-name="breakingnews"
    table-css-class="breakingnews-table"
    :show-detail-button="() => false"
    :show-edit-button="showEditButton"
    :show-actions="false"
  >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="`${item.title}: ${item.text}`"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="getSiteNames(item.siteIds)"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.publishedFrom ? $options.filters.prettyDateTime(item.publishedFrom) : '-'"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.publishedTo ? $options.filters.prettyDateTime(item.publishedTo) : '-'"
      />
      <AbstractColumn :header="tableHeaders[5].name">
        <span
          v-if="item.publishedTo"
          class="label label-warning"
        >
          {{ $t('breakingnews.inactive') }}
        </span>
        <span
          v-else
          class="label label-success"
        >
          {{ $t('breakingnews.active') }}
        </span>
      </AbstractColumn>
      <AbstractColumn :header="tableHeaders[0].name">
        <div class="actions">
          <ButtonTableAction v-if="showEditButton" icon="edit" @click.stop="$refs.breakingNewsTable.editButtonClick(item)" />
          <ButtonDelete
            v-if="isDeleteButtonVisible(item)"
            store-name="breakingnews"
            :record-id="item.id"
            action-id-param="id"
            store-action-reload-name="fetch"
          />
        </div>
      </AbstractColumn>
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import AbstractColumn from '@/components/table/columns/AbstractColumn'
import TitleColumn from '@/components/table/columns/TitleColumn'
import PermissionService from '@/services/PermissionService'
import SiteService from '@/services/site/SiteService'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import ButtonDelete from '@/components/buttons/ButtonDelete'

export default {
  name: 'BreakingNewsTable',
  props: {
    list: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    AbstractDataTable,
    AbstractColumn,
    TitleColumn,
    ButtonTableAction,
    ButtonDelete
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('breakingnews.list.id') },
        { name: this.$t('breakingnews.list.message') },
        { name: this.$t('breakingnews.list.site') },
        { name: this.$t('breakingnews.list.from') },
        { name: this.$t('breakingnews.list.to') },
        { name: this.$t('breakingnews.list.published') },
        { name: this.$t('actions') }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.BREAKING_NEWS_PERMISSIONS
    }
  },
  methods: {
    getSiteNames (siteIds) {
      if (siteIds.length > 0) {
        return siteIds.map((siteId) => {
          return SiteService.getSiteTitle(siteId)
        }).join(', ')
      }
    },
    showEditButton () {
      return this.$store.getters['user/hasPermission'](this.requiredPermissions.editButton)
    },
    isDeleteButtonVisible (item) {
      return this.$store.getters['user/hasPermission'](this.requiredPermissions.deleteButton) && item.publishedTo
    }
  }
}
</script>
<style lang="scss">
  .breakingnews-table {
    margin: 0;
    padding: 0;

    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(40px) 1fr 1fr rem(130px) rem(130px) rem(80px) rem(80px);
      }
    }
    &__drag-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: 0;
      cursor: move;
    }
    &__drag-button-icon {
      width: rem(26px);
      height: 100%;
      fill: #8A96AC;
    }
  }
  .actions {
    display: flex;
    gap: rem(6px);
  }
</style>
