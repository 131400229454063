<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="breakingnews_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-filter></app-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <BreakingNewsTable
            :list="list"
            :totalCount="totalCount"
            :current-page="page"
            :on-page-change="setPageAndGetRecords"
          />
          <div v-if="callingAPI === false && list.length == 0" class="m-t-10">
            <p class="alert alert-info">
              {{ $t('result_not_found') }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import ModuleView from '@/components/ModuleView'
import ButtonCreate from '@/components/shared/ButtonCreate'
import Filter from '@/components/filter/FilterBreakingNews'
import PermissionService from '@/services/PermissionService'
import BreakingNewsTable from '@/components/table/BreakingNewsTable'

export default {
  name: 'HotTopicsListView',
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFilter: Filter,
    appButtonCreate: ButtonCreate,
    BreakingNewsTable
  },
  data () {
    return {
      dataLoaded: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.BREAKING_NEWS_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    list () {
      return this.$store.getters['breakingnews/list']
    },
    totalCount () {
      return this.$store.getters['breakingnews/totalCount']
    },
    page () {
      return this.$store.getters['breakingnews/page']
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getList () {
      this.$store.dispatch('breakingnews/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('breakingnews/setPage', page)
      this.getList()
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.hottopics-list {
  &__title {
    color: #8A96AC;
  }
}
</style>
