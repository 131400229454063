<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterList">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model.trim="filter.text"
                id="filter_text"
                :label="$t('breakingnews.filter.text')"
              >
              </app-input>
            </div>
            <div class="col-lg-4">
              <app-multi-select
                :value="filter.site ? filter.site.map(siteId => sites.find(site => site.id === siteId)) : []"
                @input="(siteIds) => filter.site = siteIds ? siteIds.map(site => site.id) : []"
                :options="sites"
                label="title"
                track-by="id"
                id="filter_site"
                :label-desc="$t('breakingnews.filter.site')"
              >
              </app-multi-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.isPublished"
                :options="breakingNewsStateValues"
                :label="$t('breakingnews.filter.published')"
                id="filter_isPublished"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <div class="actions">
                <button
                  type="button"
                  class="btn btn-success pull-right m-t-30"
                  data-test="search"
                  @click="filterList"
                >
                  {{ $t('breakingnews.filter.search') }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary m-t-30"
                  data-test="reset_filter"
                  @click="resetFilter"
                >
                  {{ $t('buttons.reset_filter') }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import BreakingNewsTypeMixin from '@/components/mixins/valueObject/BreakingNewsTypeMixin'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import BreakingNewsFilter from '@/model/BreakingNewsFilter'
import { MODULE_MODULES_BREAKING_NEWS } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'BreakingNewsFilter',
  mixins: [BreakingNewsTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(BreakingNewsFilter)
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_BREAKING_NEWS)
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appMultiSelect: MultiSelect
  },
  methods: {
    filterList () {
      this.$store.commit('breakingnews/setPage', 1)
      this.$store.commit('breakingnews/setFilter', this.filter)
      this.$store.dispatch('breakingnews/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(BreakingNewsFilter)
      this.$store.commit('breakingnews/setPage', 1)
      this.$store.commit('breakingnews/resetFilter')
      this.$store.dispatch('breakingnews/fetch')
    }
  },
  created () {
    this.filter = this.$store.getters['breakingnews/filter']
  }
}
</script>
